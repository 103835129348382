.title{
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 16px;
}
.titleNumber{
    color: #45BABC;
}
.titleStatus{
    display: flex;
}
.content{
    margin: 16px -16px;
    padding: 16px;
}

.statusBar{
    display: grid;
    /*grid-template-columns: repeat(2, 1fr);*/
    /*gap: 8px 56px;*/
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(69, 186, 188, 0.5);
}
.statusBarRow{
    display: flex;
    flex-direction: column;
}
.statusBarLabel{
    padding-right: 4px;
}

.top{
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    margin-bottom: 20px;
}
.topCol{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.topRow{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.table {
    max-width: 100%;
    margin-bottom: 20px;
    color: #000000;
}
.tableHead {
    display: none;
}
.tableBody{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.item {
    display: flex;
    flex-direction: column;
    border: 1px solid #D9D9D9;
}
.itemInner{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}
.item label{
    color: #ACAEC9;
}
.itemNote {
    padding: 0 16px 16px 16px;
}
.itemNoteLabel{
    color: #ACAEC9;
}
.itemActions {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    gap: 16px;
}

.summary {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    border: 1px solid #e4e2e2;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.summaryItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
}
.summaryItem:not(:last-child) {
    border-bottom: 1px solid #dddddd;
}
.summaryTitle{
    display: flex;
    align-items: center;
}
.attachmentsBlock{
    margin-top: 32px;
}
.attachmentsBlockItem{
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.modal{
    padding: 40px;
}
.modalBudgetInner{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding: 16px 32px;
    background-color: #F4F4F4;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}
.modalBudgetCol{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.modalBudgetRow{
    display: flex;
    align-items: center;
    gap: 32px;
}
.modalFormInner{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
}
.modalDocs{
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}
.linkHighlighted{
    font-family: "Quicksand", sans-serif;
    color: #C85D9F;
    text-decoration: underline;
    cursor: pointer;
}
.linkDownload{
    display: flex;
    align-items: baseline;
    gap: 4px;
    cursor: pointer;
}
.receivedItemsTable{
    margin-top: 20px;
}
.paymentsExpanded{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: -1px;
    border-top: 1px solid #ffffff;
}
.paymentsExpandedItem{
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
}
.history{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.historyItem{
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border: 1px solid #D9D9D9;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
}
.historyInfo{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.historyTime{
    color: #ACAEC9;
    font-style: italic;
}
.historyResult{
    margin-left: auto;
}
.notes{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.notesHead{
    display: flex;
    align-items: baseline;
    gap: 16px;
    margin-bottom: 8px;
}
.notesTime{
    color: #ACAEC9;
}

.summaryWrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.vendorNotes{
    flex: 1;
    word-break: break-word;
}

@media all and (min-width: 768px){
    .titleStatus{
        margin-left: auto;
    }
    .content{
        margin: 40px 0;
        padding: 40px;
    }
    .statusBar{
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;
    }
    .statusBarRow{
        flex-direction: row;
    }
    .top{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;
        margin-bottom: 0;
    }
    .topCol{
        gap: 20px;
    }

    .table{
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
        color: #000000;
        border: 1px solid #D9D9D9;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
        overflow-x: auto;
    }
    .tableHead{
        color: #ACAEC9;
    }
    .tableBody{
        display: block;
        width: fit-content;
    }
    .item{
        border: none;
        border-top: 1px solid #D9D9D9;
    }
    .tableHead, .itemInner {
        display: grid;
        grid-template-columns: 32px minmax(350px, 4fr) minmax(240px, 2fr) minmax(110px, 1fr) minmax(110px, 1fr) minmax(200px, 1fr) minmax(104px, 1fr) 32px;
        gap: 16px;
        padding: 20px;
    }
    .itemInner{
        align-items: center;
    }
    .itemNote {
        margin-right: 196px;
        padding: 0 20px 20px 20px;
    }
    .itemNoteLabel{
        margin-bottom: 12px !important;
    }
    .itemActions {
        position: static;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }
    .label{
        display: none !important;
    }
    .historyInfo{
        flex-direction: row;
        align-items: baseline;
        gap: 8px;
    }
    .notes{
        gap: 32px;
    }
    .summaryWrapper{
        flex-direction: row;
        gap: 32px;
    }
}

@media all and (min-width: 1024px){
    .tableBody{
        width: 100%;
    }
    .modalDocs{
        grid-template-columns: 1fr 2fr;
        gap: 30px;
    }
}
