.wrapper {
  padding: 40px;
  overflow: visible !important;
}
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 30px;
}

.checkboxes {
  display: flex;
  gap: 15%;
}
.setupTabsContainer {
  margin: 40px 0;
}
.fieldsImg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  max-width: 1000px;
  margin-top: 25px;
}
.holdingSection {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}
.poSetup{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.poFormat {
  display: flex;
  flex-direction: column;
}
.poFormatSections {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-radius: 4px;
}
.poFormatSection {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.poFormatRadio {
  display: flex;
  gap: 48px;
}
.addLink {
  text-decoration: underline;
  color: rgb(200, 93, 159);
}
.accordionItems {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: visible !important;
  border-radius: 12px;
}
.backOverviewButton {
  margin-top: 20px;
}
.newBranch {
  border: 1px solid #dddddd;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  padding: 25px 40px 30px 40px;
}
.editBranch {
  padding: 40px;
}
.branchesSection {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-radius: 12px;
}
.branch {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 12px;
  overflow: hidden;
}
.branchHead {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 12px 20px;
}
.branchHead label {
  margin-right: 22px;
}
.branchName {
  margin-left: 40px;
  margin-right: auto;
  padding-left: 40px;
  color: #8a8b9d;
  border-left: 1px solid #dddddd;
  font-size: 16px;
  line-height: 36px;
  text-transform: uppercase;
}
.branchBody {
  padding: 24px 70px 38px 20px;
  border-top: 1px solid #dddddd;
}

.glBody {
  padding: 40px;
  background-color: #fff;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.importButton {
  display: flex;
  align-items: center;
}
.uploadButton {
  width: 100%;
  border: 1px solid #000;
}
.actionMenuList {
  position: absolute;
  right: 50%;
  top: 32px;
  z-index: 2;
}
.handleMultipleItems {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 32px;
}
.sectionTitle {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  justify-content: space-between;
}
.departmentBox {
  border-radius: 12px;
  width: 100%;
}

.extendedComponent {
  padding: 28px;
  display: flex;
  gap: 100px;
  justify-content: space-evenly;
}
.extendedComponentRow {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.generatedNumber {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.limitTo {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  padding: 24px;
}

@media (max-width: 768px) {
  .newBranch {
    padding: 0px;
    border: none;
  }
  .editBranch {
    padding: 16px;
  }
  .setupTabsContainer {
    margin: 16px 0;
  }
  .limitTo {
    row-gap: 16px;
  }
  .accordionItems {
    margin-left: -16px;
    margin-right: -16px;
    border-radius: 0;
  }
}
