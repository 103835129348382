.formInner {
  margin: 16px -16px;
  padding: 16px;
}
.items {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
.itemsTable {
  max-width: 100%;
  color: #000000;
}
.itemsTableHead,
.itemsInvoiceTableHead {
  display: none;
}
.itemsTableBody{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.itemsAdd {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}
.item {
  display: flex;
  flex-direction: column;
  border: 1px solid #D9D9D9;
}
.itemInner,
.itemInvoiceInner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
.item label{
  color: #ACAEC9;
}
.itemNote,
.itemInvoiceNote {
  padding: 0 16px 16px 16px;
}
.itemActions {
  position: absolute;
  top: 8px;
  right: 16px;
  display: flex;
  gap: 16px;
}
.actionButton{
  background-color: #ECF8F8;
}
.attachmentsBlock {
  margin-top: 40px;
}
.attachmentsBlockItem{
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.repeat {
  margin-top: 40px;
}
.repeatFields {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr 1fr;
  gap: 40px;
  margin-top: 40px;
}
.repeatTwoInputs {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 20px;
}
.modal{
  padding: 40px;
}
.tooltipRow{
  display: flex;
  gap: 8px;
  padding: 4px;
}
.noOptionRedirect{
  display: flex;
  justify-content: center;
  padding: 8px;
}

@media all and (min-width: 768px){
  .formInner {
    margin: 40px 0;
    padding: 40px;
    overflow: initial !important;
  }
  .items {
    margin: 40px 0;
  }
  .itemsTable {
    border: 1px solid #D9D9D9;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow-x: auto;
  }
  .itemsTableHead,
  .itemsInvoiceTableHead {
    display: grid;
    gap: 16px;
    padding: 20px;
    color: #ACAEC9;
  }
  .itemsTableBody {
    display: block;
  }
  .item{
    border: none;
    border-top: 1px solid #D9D9D9;
  }
  .itemInner,
  .itemInvoiceInner {
    display: grid;
    align-items: center;
    gap: 16px;
    padding: 20px;
  }
  .itemsTableHead, .itemInner{
    grid-template-columns: 32px minmax(300px, 4fr) minmax(250px, 2fr) minmax(80px, 1fr) minmax(80px, 1fr) minmax(200px, 1fr) minmax(106px, 1fr) 120px;
  }
  .itemInvoiceInner, .itemsInvoiceTableHead{
    grid-template-columns: minmax(280px, 4fr) minmax(145px, 2fr) minmax(110px, 1fr) minmax(110px, 1fr) minmax(110px, 1fr) minmax(170px, 1fr) minmax(100px, 1fr) 80px;
    gap: 16px;
  }
  .label{
    display: none !important;
  }
  .itemActions {
    position: static;
    justify-content: flex-end;
  }
  .itemNote,
  .itemInvoiceNote {
    margin-right: 130px;
    padding: 0 20px 20px 20px;
  }
  .itemsAdd {
    flex-direction: row;
  }
}
