.wrapper {
  overflow: visible !important;
}
.title {
  margin-bottom: 40px;
}
.company {
  background-color: #f4f4f4;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
}
.companyContent {
  padding: 40px;
  overflow: visible !important;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.saveButton {
  margin-bottom: 50px;
}
.approvalTab {
  margin-bottom: 50px;
}

.selectedValue {
  display: flex;
  background: #f4f4f4;
  padding: 10px 13px;
  border-radius: 4px;
  gap: 80px;
  width: max-content;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 300;
}
.rules {
  padding-bottom: 20px;
}
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  height: 532px;
}
.noDataContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
}
.rulesRadio {
  display: flex;
  align-items: end;
  gap: 60px;
  transform: translate(0, -15px);
}
.dragButton {
  border: none;
  background: none;
  cursor: pointer;
}
.addRuleContainer {
  padding-bottom: 45px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}
.row {
  padding: 40px 40px 0 40px;
  padding-right: 70px;
}
.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0 40px;
}
.rowContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 95%;
  padding: 0;
}
.conditionsTitle {
  padding: 0 40px;
  margin-bottom: 24px;
}
.conditions {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.singleCondition {
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 0 40px;
}
.deleteCondition {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
.deleteCondition img {
  width: 100%;
  height: 100%;
}
.approvalHierarchyContainer {
  padding: 40px 40px 0 40px;
}
.approvalHierarchy {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.hierarchyRadio {
  display: flex;
  gap: 20px;
}
.userDropDownContainer {
  display: flex;
  gap: 30px;
}
.userDropDown {
  width: 33%;
}
.userList {
  width: 67%;
  border-radius: 8px;
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  padding: 24px;
}
.buttons {
  display: flex;
  gap: 30px;
  padding-left: 40px;
  padding-bottom: 40px;
}
.ruleTable {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  margin-bottom: 20px;
}
.tableRow {
  display: grid;
  grid-template-columns: 3% 17% 30% 20% 20% 5%;
  min-height: 60px;
  align-items: center;
  padding: 0 20px;
  font-weight: 500;
  border-bottom: 1px solid #d9d9d9;
  gap: 1%;
}
.tableRow:last-child {
  border-bottom: none;
}
.tableRow:hover {
  background: rgba(69, 186, 188, 0.1);
  transition: all 0.1s ease-in-out;
}
.tableHeader {
  color: #acaec9;
  display: grid;
  grid-template-columns: 3% 17% 30% 20% 20% 5%;
  min-height: 60px;
  align-items: center;
  padding: 0 20px;
  font-weight: 500;
  border-bottom: 1px solid #d9d9d9;
  gap: 1%;
}
.paginationContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.pagination {
  display: flex;
  gap: 10px;
}
.itemsPerPage {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 100%;
  max-width: 300px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #fff;
  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
.itemsPerPageSelect {
  width: 50%;
}
.itemsQuantity {
  padding: 10px 20px;
}
.deleteButton {
  order: 2;
  padding: 0;
}
.submitButtons {
  padding: 0 40px;
}
.addConditionButton {
  justify-content: start;
  margin-top: 16px;
  padding: 0 40px;
}
@media (max-width: 768px) {
  .addRuleContainer {
    margin-bottom: 0px;
  }
  .row {
    padding: 16px 16px 0 16px;
  }
  .container {
    padding: 0 16px;
  }
  .conditionsTitle {
    padding: 0 16px;
    margin-bottom: 20px;
  }
  .conditions {
    row-gap: 0;
  }
  .conditions:last-child {
    row-gap: 0;
    border-bottom: 1px solid #d9d9d9;
  }
  .singleCondition {
    flex-direction: column;
    align-items: start;
    gap: 16px;
    border-top: 1px solid #d9d9d9;
    padding: 16px 0;
  }
  .rowContainer {
    grid-template-columns: 1fr;
    order: 2;
    gap: 16px;
    width: 100%;
    padding: 0 16px;
  }
  .deleteButton {
    order: 1;
    display: flex;
    width: 100%;
    justify-content: end;
    padding: 0 16px;
  }
  .submitButtons {
    padding: 0 16px 16px 16px;
  }
  .addConditionButton {
    display: flex;
    justify-content: end;
    padding: 0 16px;
  }
  .userDropDownContainer {
    flex-direction: column;
    row-gap: 30px;
  }
  .userDropDown {
    width: 100%;
  }
  .userList {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 16px;
  }
}
