.budgetAdd {
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}
.budgetForm {
  padding: 40px;
}
.budgetVersion {
  max-width: 180px;
}
.editBudgetData {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
}
.formField {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.notesContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.totalBudgetValue {
  display: flex;
  align-items: center;
  gap: 8px;
}
.budgetStats {
  flex: 0 0 50%;
  display: flex;
}
.budgetChart {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  border-left: 1px solid #d9d9d9;
  padding-left: 40px;
}
.chart {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 50px;
}
.percentage {
  position: absolute;
}

.savedField {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 80px;
}

.glTitle {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}
.titleButton {
  display: flex;
  gap: 20px;
}
.addGl {
  padding: 0 40px;
}
.glForm {
  padding: 40px;
  margin-bottom: 30px;
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  row-gap: 16px;
}
.addLink {
  color: #c85d9f;
}
.numberInput {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 4px 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4em;
  color: #0c0c0c;
  border: 1px solid #e4e2e2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  outline: none;
}
.expandedGlContainer {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.expandedGlLine {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.expandedGlLineTextValue{
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}
.textIconDiv {
  display: flex;
  background: #dddddd;
  justify-content: center;
}

.deleteButtonContainer {
  position: absolute;
  top: -55px;
  right: 0;
}

.iconStyle {
  margin: 1rem 0 0 47rem;
  width: 3rem !important;
  cursor: pointer;
  position: absolute;
}
.totalAmountError {
  transform: translate(0, 10px);
  position: static;
}
.pendingDataContainer {
  display: flex;
  align-items: center;
}
.glTable {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 12px;
}
.approvedBudgetNotes {
  padding: 40px;
}
.editBudgetSection {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.savedBudgetData {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 40px;
}

.budgetUsed {
  text-align: center;
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  padding: 24px;
}

@media (max-width: 768px) {
  .budgetAdd {
    flex-direction: column;
    gap: 0px;
    padding: 0;
  }
  .budgetForm {
    width: auto;
    order: 2;
    padding: 30px;
  }
  .budgetData {
    align-items: center;
  }
  .budgetStats {
    border-bottom: 1px solid #d9d9d9;
    order: 1;
  }
  .savedField {
    grid-template-columns: 1fr 1fr;
  }
  .glForm {
    padding: 0px;
    border: none;
  }
  .errorContainer label {
    margin: 0px;
  }
  .errorContainer p {
    display: none;
  }
}
