.auth {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 16px;
	background-image: url(../../public/assets/img/login_register/log_reg_page_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
}
.buttons {
	display: flex;
	flex-direction: row;
	gap: 30px;
	margin: 0 auto 30px auto;
	text-align: center;
}
.authInner {
	width: 100%;
	max-width: 1400px;
	/*max-width: 1600px;*/
}
.authInnerSmall {
	width: 100%;
	max-width: 800px;
}
.authContainer {
	display: flex;
	flex-direction: column;
}
.authImage {
	display: none;
}
.imageTexts {
	color: #ffffff;
}
.imageTextsReg {
	margin-top: 30px;
}
.imageTextsReg h2 {
	margin-bottom: 4px;
}
.imageTextsLogin {
	max-width: 420px;
	margin-top: 24px;
}
.imageTextsLogin h2 {
	margin-bottom: 20px;
}
.imageText {
	display: flex;
	align-items: center;
	margin-top: 12px;
}
.imageText svg {
	margin-right: 12px;
}
.authContent {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	padding: 16px;
}
.authContentLogo {
	margin-bottom: 24px;
}
.authHeader,
.authHeaderSmall,
.authHeaderFull {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 0 auto 30px auto;
	text-align: center;
}
.authHeader {
	max-width: 550px;
	align-items: center;
}
.authHeader canvas {
	margin-bottom: 32px;
}
.authHeaderSmall {
	max-width: 430px;
}
.cnpHeader {
	align-items: center;
	max-width: 460px;
}
.authHeader p {
	color: #595858;
}
.authHeaderImg {
	display: flex;
	justify-content: center;
	max-height: 130px;
	margin-bottom: 24px;
}
.authForm {
	width: 100%;
}
.authForm {
	width: 100%;
}
.inputs {
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;
	width: 100%;
}
.passwordRequirements {
	margin-top: 24px;
}
.checkboxWrapper {
	display: flex;
	margin-top: 24px;
	color: #333;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.4em;
}
.checkboxWrapper label {
	margin-bottom: 0;
}
.btn {
	width: 100%;
	max-width: 360px;
	margin: 32px auto 16px auto;
}
.btnVertical {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	max-width: 360px;
	margin: 32px auto 0 auto;
}
.btnBefore {
	max-width: 640px;
	margin-top: 24px;
	color: #595858;
}
.btnBeforeLogin {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
}
.btnBeforeLogin span {
	font-size: 16px;
	font-weight: 500;
	text-decoration: underline !important;
}
.btnBeforeLogin span:hover {
	color: #c85d9f;
	cursor: pointer;
}
.btnAfter {
	color: #595858;
}
.btnAfter a {
	text-decoration: underline !important;
}
.modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px;
	text-align: center;
}
.modalTextOnly,
.modalSmallPadding {
	padding: 32px;
}
.modalTextOnly p {
	margin-top: 8px;
}
.modal img {
	margin-bottom: 30px;
}
.modal p {
	margin-top: 10px;
	color: #595858;
}
.passwordChangeRules {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 16px;
	color: #595858;
}
.modalThanksInner {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 440px;
}
.modalThanksInner + button {
	max-width: 360px;
	margin-top: 30px;
}
.totpList {
	max-width: 500px;
	margin-bottom: 30px;
}
.totpList ul {
	margin: 0;
}
.inputsCode {
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.forgotPasswordCode {
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-bottom: 10px;
}
.mb-5 {
	margin-bottom: 5px;
}
.innerPadding {
	padding: 30px;
}

.verificationForm{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
}
.verificationCodeLabel{
	color: #acaec9;
}
.timer{
	display: flex;
	gap: 8px;
}

@media all and (min-width: 768px) {
	.authImage {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 60px;
		background-color: #39babd;
		background-image: url(../../public/assets/img/login_register/log_reg_bg.png);
		background-repeat: no-repeat;
		background-size: cover;
	}
	.authContent {
		padding: 60px;
	}
	.authFormSmall {
		max-width: 360px;
	}
	.inputs {
		grid-template-columns: repeat(2, 1fr);
		gap: 24px 30px;
	}
	.inputsColomn {
		grid-template-columns: 1fr;
	}
	.modal {
		padding: 100px;
	}

	.inputs.forgotPassword {
		grid-template-columns: repeat(2, 1fr);
		gap: 24px 30px;
	}
}
@media all and (min-width: 1024px) {
	.auth {
		padding: 32px 16px;
	}
	.authContainer {
		flex-direction: row;
	}
	.authContent {
		justify-content: center;
	}
	.authContentLogo {
		margin-bottom: 60px;
	}
	.authImage {
		width: 45.5%;
		padding: 40px 60px;
	}
}
