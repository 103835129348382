.top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.filter {
  min-width: 100%;
}
.setup {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 36px 30px;
}
.setupSmall {
  background: transparent !important;
  box-shadow: none !important;
}
.text p {
  margin-top: 4px;
  color: #565656;
}
.steps {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}
.steps:after {
  content: '';
  position: absolute;
  top: 24px;
  left: 5%;
  width: 92%;
  height: 2px;
  background-color: #dddddd;
  z-index: -1;
}
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  height: 49px;
  margin-bottom: 16px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid #dddddd;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #d9d9d9;
  pointer-events: none;
}

.stepTitle {
  margin-bottom: 10px;
}
.stepLink {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #c85d9f;
}
.stepLink svg {
  margin-left: 8px;
}
.stepDone .stepNumber {
  border-color: #45babc;
  background-color: #45babc;
  color: #ffffff;
  pointer-events: all;
}

.countersWrapper{
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}
.countersCol:last-child .counters:after{
  content: "";
  position: absolute;
  right: 0;
  top: -17px;
  left: 0;
  height: 2px;
  background-color: #dddddd;
}
.countersCol{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.counters {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  height: 100%;
  padding: 16px;
}
.counterDisabled {
  background-color: #dddddd !important;
  opacity: 0.75;
  cursor: not-allowed;
}
.counterValue {
  color: #c85d9f;
}
.counterLabel {
  color: #a4a5cb;
  text-align: center;
}
.counterIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  height: 60px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: rgba(69, 186, 188, 0.1);
  color: rgba(69, 186, 188, 1);
}
.chartTop {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}
.chartActions {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.chartTotal {
  color: #acaec9;
}
.budgetsSearch {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media all and (min-width: 768px) {
  .counters {
    grid-template-columns: repeat(2, 1fr);
  }
  .filter {
    min-width: 200px;
  }
  .budgetsSearch {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 20px;
  }
}
@media all and (min-width: 1024px) {
  .top {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .counterLabel {
    text-align: left;
  }
  .chartTop {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .chartActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }
}
@media all and (min-width: 1366px) {
  .setup {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .counters {
    grid-template-columns: repeat(3, 1fr);
  }
}
