.title {
  margin-bottom: 40px;
}
.users {
  display: flex;
  flex-direction: column;
}

.userDropDownContainer {
  display: flex;
  gap: 30px;
  padding-right: 70px;
}
.userDropDown {
  width: 33%;
}
.submitButtons {
  padding: 0 40px;
}

@media (max-width: 768px) {
  .addConditionContainer {
    row-gap: 32px;
  }

  .userDropDownContainer {
    padding: 0;
  }
  .submitButtons {
    padding: 0 16px 16px 16px;
  }
}
