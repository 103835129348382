html{
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}
body {
	margin: 0;
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
	color: #0c0c0c;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	color: inherit;
}
a,
.link {
	font-weight: 500;
	color: #000;
}
a:hover,
.link:hover {
	color: #c85d9f;
	cursor: pointer;
}
a {
	text-decoration: none;
}
.link {
	text-decoration: underline;
}
img {
	max-width: 100%;
}
form{
	max-width: 100%;
}
.oneLineText {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
/*PhoneInput*/
.PhoneInput {
	position: relative;
	display: block !important;
}
.PhoneInputCountry {
	position: absolute !important;
	top: 50%;
	left: 20px;
	transform: translateY(-50%);
	padding-right: 15px;
	border-right: 1px solid #8a8b9d;
}
.PhoneInputInput {
	display: flex;
	align-items: center;
	width: 100%;
	height: 50px;
	padding: 10px 20px 10px 90px;
	border: 1px solid #e4e2e2;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.4em;
	color: #0c0c0c;
	outline: none;
}
.error .PhoneInputInput {
	border-color: #cd3e27;
}
.PhoneInputInput::placeholder {
	color: #8a8b9d;
}
.PhoneInputInput:focus,
.PhoneInputInput:focus-visible {
	border-color: #595858;
}
.error .PhoneInputInput:focus,
.error .PhoneInputInput:focus-visible {
	border-color: #cd3e27;
}
.PhoneInputCountryIcon--border {
	min-width: 20px;
	width: 20px !important;
	height: 20px !important;
	margin-top: 5px;
	margin-bottom: 5px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	box-shadow: none !important;
	overflow: hidden;
}
.PhoneInputCountryIconImg {
	object-fit: cover;
}
.PhoneInputCountrySelectArrow {
	margin-left: 13px !important;
}
/*react-select*/
.react-select-container {
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.4em;
	color: #0c0c0c;
}
.react-select-container.small {
	min-width: 140px;
	font-size: 12px;
}
.react-select-container.table-pagination {
	font-size: inherit;
}
.react-select__control {
	min-height: 40px !important;
	border-color: #e4e2e2 !important;
	box-shadow: none !important;
}

.companySwitch{
	margin-right: auto;
}
.companySwitch .react-select__control {
	background-color: #c85d9f;
}
.companySwitch .react-select__single-value {
	color: #ffffff;
	font-weight: 600;
}

.small .react-select__control {
	min-height: 40px !important;
}
.table-pagination .react-select__control {
	min-height: 40px !important;
}
.error .react-select__control {
	border-color: #cd3e27 !important;
}
.react-select__control:hover {
	border-color: #e4e2e2 !important;
}
.error .react-select__control:hover {
	border-color: #cd3e27 !important;
}
.react-select__control--is-focused,
.react-select__control--is-focused:hover {
	border-color: #595858 !important;
}
.error .react-select__control--is-focused,
.error .react-select__control--is-focused:hover {
	border-color: #cd3e27 !important;
}
.small .react-select__value-container {
	padding: 2px 10px !important;
}
.react-select__placeholder {
	color: #8a8b9d !important;
	margin-left: 0 !important;
}
.react-select__menu{
	z-index: 2 !important;
}
.inp-container {
	position: relative;
}
.inp-united-select-small{
	display: flex;
}
.inp-united-select-small > div:first-child{
	width: 100%;
	min-width: 120px;
	max-width: 120px;
}
.mb-10 {
	margin-bottom: 30px;
}
.error-message {
	position: absolute;
	top: 100%;
	left: 0;
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	white-space: nowrap;
	color: #cd3e27;
}
.react-datepicker-wrapper.custom-datepicker {
	display: block;
}

.vertical-collpsed main {
	margin-left: 70px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#spinner {
	width: 100%;
	z-index: 56765;
	background: #fff;
	height: 100%;
	top: 0;
	position: fixed;
}
/*react-data-table overrides*/
.rdt_TableHead{
	background: yellow;
}
@media print {
	.print-div {
		margin: 2rem;
		padding: 1rem;
	}

	.print-btn {
		display: none;
	}
}
@media all and (max-width: 767px){
  html{
    scroll-padding-top: 65px;
  }
}
@media all and (min-width: 768px){
	.react-select-container {
		font-size: 16px;
	}
	.react-select__control {
		min-height: 50px !important;
	}
}


