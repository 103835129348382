.container{
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
}
.h2, .h2Underlined{
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
}
.h2Underlined:after, .h2UnderlinedPink:after{
    content: "";
    display: block;
    width: 108px;
    height: 3px;
    margin-top: 20px;
}
.h2Underlined:after{
    background-color: #45BABC;
}
.h2UnderlinedPink:after{
    background-color: #C85D9F;
}
.h3{
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
}
.h4{
    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
}
.p{
    margin: 0;
    font-size: 24px;
    line-height: 34px;
}
.title{
    width: 100%;
    max-width: 477px;
    margin: 0 auto;
    text-align: center;
}

.header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 24px 0;
    background-color: #fff;
    font-size: 18px;
    line-height: 28px;
    box-shadow: 0 1px 0 0 #E6EAF2;
    z-index: 999;
}
.headerInner{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.mobileMenu{
    display: none;
}
input[name="showMobileMenu"]{
    display: none;
}
.headerMenu{
    display: flex;
    gap: 50px;
    margin: 0;
    padding: 0;
    list-style: none;
}
.headerActions{
    display: flex;
    align-items: center;
    gap: 30px;
}

.hero{
    margin-top: 100px;
    padding: 172px 0 128px 0;
    background-color: #000000;
    color: #ffffff;
    background-image: url("../../public/assets/img/home/hero-bg.jpg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
.heroInner{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}
.heroInfo{
    width: 100%;
    max-width: 643px;
}
.heroTitle{
    font-weight: 800;
    font-size: 46px;
    line-height: 56px;
}
.heroDescription{
    margin-top: 32px;
    margin-bottom: 60px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
}
.heroAction{
    margin-left: 68px;
    margin-bottom: 100px;
}
.heroAction > img{
    display: none;
}
.heroBtn{
    width: 180px;
    height: 180px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    cursor: pointer;
}

.features{
    padding: 80px 0;
    background-color: #F8F8FA;
}
.featuresItems{
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 72px;
}
.featuresItem{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}
.featuresItem:nth-child(even){
    flex-direction: row-reverse;
}
.featuresText{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 477px;
    gap: 10px;
}

.pricing{
    padding: 64px 0;
}
.pricingCta{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}
.pricing .pricingCtaBtn{
    min-width: 350px;
}
.pricingH2{
    margin-bottom: 32px;
}
.pricingP{
    margin: 12px 0 0 0;
    font-size: 14px;
    line-height: 18px;
}
.pricingTable{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 64px;
}
.pricingCol{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.pricingTableHead{
    margin-bottom: 24px;
    background-color: #FDFDFD;
    border: 2px solid #e5e5e5;
    text-align: center;
}
.pricingTableHeadSpecial{
    box-shadow: 0 0 70px rgba(0,0,0, 0.1);
    border: none;
}
.pricingTableHeadTop{
    padding: 14px 20px;
    background-color: #E1F4F4;
    border-bottom: 2px solid #e5e5e5;
}
.pricingTableHeadSpecial .pricingTableHeadTop{
    background-color: #45BABB;
    border: 2px solid #45BABB;
    color: #ffffff;
}
.pricingTableHeadTitle{
    margin: 0;
    font-size: 24px;
    line-height: 30px;
    color: #C85D9F;
}
.pricingTableHeadSpecial .pricingTableHeadTitle{
    color: #ffffff;
}
.pricingTableHeadDescription{
    margin: 12px 0 0 0;
    font-weight: 500;
}
.pricingTableHeadContent{
    padding: 48px;
}
.pricingTableHeadSpecial .pricingTableHeadContent{
    border-right: 2px solid #e5e5e5;
    border-bottom: 2px solid #e5e5e5;
    border-left: 2px solid #e5e5e5;
}
.pricingTableHeadPrice{
    font-size: 48px;
    line-height: 40px;
    font-weight: 700;
}
.pricingTableHeadPricePeriod{
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
}
.pricingTableHeadPriceDescription{
    margin-top: 20px;
    font-size: 16px;
    line-height: 20px;
}
.pricingBox{
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 60px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 20px;
}
.pricingBox:not(:last-child){
    border-bottom: 1px solid #e5e5e5;
}

.integration{
    padding: 60px 0;
    background-color: #F8F8FA;
    text-align: center;
}
.integrationText{
    margin-bottom: 52px;
}
.integrationSubtitle{
    margin-top: 8px;
}
.integrationItems{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 30px;
}
.integrationItem{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    padding: 8px;
    border: 1px solid #D9D9D9;
    background-color: #fff;
}

.cta{
    padding: 108px 0;
    background-color: #ECF8F8;
}
.ctaInner{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    gap: 40px;
}
.ctaText{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.ctaBtns{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
.ctaBtns button{
    min-width: 250px;
}

.contact{
    padding: 64px 0;
}
.contactInner{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 32px;
    align-items: center;
}
.contactForm{
    margin-top: 40px;
}
.contactFields{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;
}

.footer{
    color: #ffffff;
    background-color: #000000;
}
.footer a:not(:hover){
    color: #ffffff;
}
.footer img, .footer svg{
    display: block;
}
.footerInner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 88px 0;
}
.footerGeneral{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    max-width: 445px;
    font-size: 16px;
    line-height: 30px;
}
.footerSocials{
    display: flex;
    gap: 28px;
}
.footerStores{
    display: flex;
    gap: 32px;
    margin-top: auto;
}
.footerInfo{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 445px;
    font-size: 16px;
    line-height: 30px;
}
.copyrights{
    padding: 32px 15px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    border-top: 1px solid #393939;
}

@media all and (max-width: 767px){
    .h2, .h2Underlined{
        font-size: 24px;
        line-height: 30px;
    }
    .h2Underlined:after, .h2UnderlinedPink:after{
        width: 100px;
        margin-top: 14px;
    }
    .h3{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
    }
    .p{
        font-size: 16px;
        line-height: 20px;
    }
    .title{
        width: 90%;
    }

    .mobileMenu{
        display: block;
        width: 28px;
        height: 22px;
    }
    .mobileMenu span{
        display: block;
        height: 3px;
        background-color: #45BABC;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .mobileMenu span:nth-child(1){
        width: 20px;
    }
    .mobileMenu span:nth-child(2){
        margin: 6px 0;
    }
    .mobileMenu span:nth-child(3){
        width: 14px;
    }
    .header{
        padding: 16px 0;
    }
    .headerLogo img{
        display: block;
        width: 90px;
    }
    .headerMenu{
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 65px;
        left: -100%;
        gap: 24px;
        width: 100%;
        padding: 24px 16px;
        background-color: #fff;
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;
        visibility: hidden;
        z-index: 9;
    }
    input[name="showMobileMenu"]:checked +  .headerMenu{
        left: 0;
        visibility: visible;
    }
    .headerActions{
        gap: 16px;
        font-size: 14px;
    }
    .headerActions button{
        min-width: 100px;
        height: 30px;
        font-size: 12px;
    }
    .hero{
        margin-top: 65px;
        padding: 0;
        color: #000000;
        background: #F8F8FA;
        text-align: center;
    }
    .heroInner{
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: stretch;
    }
    .heroInfo{
        padding: 24px 0;
    }
    .heroTitle{
        font-size: 24px;
        line-height: 34px;
    }
    .heroDescription{
        margin-top: 16px;
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 22px;
    }
    .heroAction{
        position: relative;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 0;
        background-color: #000000;
    }
    .heroAction > img{
        display: block;
        width: 100%;
    }
    .heroBtn{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 54px;
        height: 54px;
        margin-top: -27px;
        margin-left: -27px;
        z-index: 2;
    }
    .features{
        padding: 40px 0;
    }
    .featuresItems{
        margin-top: 32px;
    }
    .featuresItem:nth-child(even){
        flex-direction: row;
    }

    .pricing{
        padding: 40px 0;
    }
    .pricing .pricingCtaBtn{
        min-width: auto;
    }
    .pricingCol{
        gap: 0;
    }
    .pricingTable {
        grid-template-columns: 1fr;
        gap: 30px;
        margin-top: 32px;
    }
    .pricingBox{
        min-height: auto;
        padding: 12px 0;
    }
    .pricingTableHeadContent{
        padding: 16px;
    }
    .pricingTableHeadDescription{
        margin: 4px 0 0 0;
    }
    .pricingTableHeadPrice{
        font-size: 36px;
    }
    .pricingTableHeadPriceDescription{
        margin-top: 8px;
    }

    .integration{
        padding: 30px 0 40px 0;
    }
    .integrationText{
        margin-bottom: 30px;
    }
    .integrationSubtitle{
        margin-top: 12px;
        font-size: 18px;
        line-height: 22px;
    }
    .integrationItems{
        gap: 20px;
    }
    .cta{
        padding: 40px 0;
    }
    .ctaInner{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .ctaBtns{
        flex-direction: column;
        gap: 20px;
    }
    .contact{
        padding: 36px 0;
    }
    .contactFields{
        margin-bottom: 24px;
    }
    .footerInner{
        padding: 40px 0;
    }
    .footerStores{
        margin-top: 28px;
    }
    .footerInfo{
        margin-top: 52px;
    }
    .copyrights{
        opacity: 0.75;
    }
}
