.linkCompany {
  margin-bottom: 30px;
}
.title {
  margin-bottom: 40px;
}
.fields {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.inputs {
  display: flex;
  gap: 30px;
}
.leftSection {
  width: 67%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.rightSection {
  width: 33%;
}
.legalEntityBodyBtns {
  padding: 40px;
  border-top: 1px solid #d9d9d9;
}
.legalEntitySetupBtns {
  margin-top: 40px;
}
.link {
  text-decoration: underline;
  color: rgb(200, 93, 159);
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  padding: 24px;
}
