.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 15px 16px;
  background-color: #fff;
  box-shadow: 0 1px 0 0 #e6eaf2;
}
.headerInner {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 65px;
  right: -100%;
  bottom: 0;
  width: 100%;
  gap: 24px;
  padding: 16px;
  background-color: #fff;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  visibility: hidden;
  z-index: 99;
}
.headerWidgetLogo {
  height: 50px;
  margin-right: auto;
}
.headerWidgetLogo img {
  max-height: 100%;
}
.headerBlock {
  position: relative;
}
.headerUser {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}
.headerAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid #45babc;
  text-transform: uppercase;
  overflow: hidden;
}
.headerDropSection {
  padding: 12px;
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: start;
}
.headerDropSection:last-child {
  border-bottom: none;
}
.headerDropUserInfo {
  display: flex;
  align-items: center;
}
.headerDropSystemAudit {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.headerText {
  display: flex;
  flex-direction: column;
}
.headerUser svg:last-child {
  margin-left: 4px;
}
.active .headerUser svg:last-child {
  transform: rotate(180deg);
}
.headerDrop {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  margin-top: 12px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 #e6eaf2;
  z-index: 9;
  width: max-content;
}

.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 65px;
  left: -100%;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  visibility: hidden;
  z-index: 99;
}
.sidebarLogo {
  display: none;
}
.sidebarCompany{
  padding: 0 16px 16px 16px;
}
.sidebarMenu {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 1px;
  overflow-y: auto;
  font-size: 16px;
  font-weight: 500;
}
.sidebarMenuHeading {
  padding: 15px 24px 10px;
  text-transform: uppercase;
  color: #acaec9;
  border-top: 1px solid #e6eaf2;
}
.sidebarMenuLink {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 14px 24px 14px 19px;
  border-left: 5px solid transparent;
}
.sidebarMenuLink:hover,
.sidebarMenuLink.active,
.active .sidebarMenuLink {
  background-color: rgba(69, 186, 188, 0.1);
  border-left: 5px solid #39b7bc;
  color: #333;
}
.sidebarMenuLink svg {
  fill: #acaec9;
}
.sidebarMenuLink:hover svg,
.sidebarMenuLink.active svg,
.active .sidebarMenuLink svg {
  fill: #39b7bc;
}
.sidebarMenuLink svg:last-child {
  margin-left: auto;
  fill: #000000;
}
.sidebarMenuLink.active svg:last-child,
.active .sidebarMenuLink svg:last-child {
  transform: rotate(180deg);
}
.sidebarMenuChildren {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 0 24px 69px;
}
.sidebarMenuChildren .active {
  font-weight: 700;
}

.content {
  flex: 1 1 auto;
  background-color: #f8f8fa;
  overflow-y: hidden;
}
.contentInner {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px;
  width: 100%;
  height: calc(100vh - 67px);
  padding: 16px;
  overflow-y: auto;
  position: relative;
}
.contentInner > * {
  flex: 1 1 100%;
}
.mobileLogo {
  display: block;
  width: 90px;
}
.mobileMenu {
  display: block;
  width: 28px;
  height: 22px;
}
.mobileMenu span {
  display: block;
  height: 3px;
  background-color: #45babc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transition: 0.4s;
}
.mobileMenu.right span {
  margin-left: auto;
}
.mobileMenu span:nth-child(1) {
  width: 20px;
}
.mobileMenu span:nth-child(2) {
  margin: 6px 0;
}
.mobileMenu span:nth-child(3) {
  width: 14px;
}
.mobileMenuOpened span:nth-child(1) {
  width: 28px;
  transform: translate(0, 11px) rotate(-45deg);
}
.mobileMenuOpened span:nth-child(2) {
  opacity: 0;
}
.mobileMenuOpened span:nth-child(3) {
  width: 28px;
  transform: translate(0, -7px) rotate(45deg);
}
.sidebarOpened {
  left: 0;
  visibility: visible;
}
.headerInnerOpened {
  right: 0;
  visibility: visible;
}
.expireModalContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.notificationsTop {
  position: relative;
}
.notificationsCounter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  left: 20px;
  min-width: 24px;
  height: 24px;
  padding: 0 8px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  background-color: rgba(69, 186, 188, 1);
  color: #ffffff;
}
.notifications {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  margin-top: 12px;
  background-color: #fff;
  box-shadow: 2.564006805419922px 0 12.820034980773926px 0 rgba(0, 0, 0, 0.1);
  z-index: 9;
}
.notificationsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.notificationsList {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px);
  padding: 0 24px 16px;
  overflow-y: auto;
}
.notificationsItem {
  position: relative;
  display: flex;
  gap: 8px;
  margin: 0 -8px;
  padding: 8px;
}
.notificationsItem:hover {
  background-color: rgba(69, 186, 188, 0.1);
}
.notificationsContent {
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.notificationsState {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: 1px solid transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  cursor: pointer;
}
.notificationsItem:hover .notificationsState {
  border-color: #acaec9;
}
.notificationsState span {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: rgba(69, 186, 188, 1);
}

@media all and (min-width: 768px) {
  .sidebar,
  .headerInner {
    max-width: 340px;
  }
  .notifications {
    width: 540px;
  }
  .contentInner {
    gap: 24px;
    padding: 24px;
  }
}
@media all and (min-width: 1367px) {
  .mobileLogo {
    display: none;
  }
  .mobileMenu {
    display: none;
  }
  .header {
    justify-content: flex-end;
  }
  .headerInner {
    position: static;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    max-width: 100%;
    padding: 0;
    visibility: visible;
  }
  .sidebar {
    position: static;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 340px;
    background-color: #fff;
    border-right: 1px solid #e6eaf2;
    visibility: visible;
  }
  .sidebarLogo {
    display: flex;
    justify-content: center;
    padding: 32px 16px;
  }
  .wrapper {
    flex-wrap: nowrap;
  }
  .header {
    gap: 24px;
    padding: 15px 80px;
  }
  .contentInner {
    height: calc(100vh - 80px);
    gap: 40px;
    padding: 40px 80px;
  }
}
