.wrapper{
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 32px 16px;
}
.header{
    display: flex;
    justify-content: center;
}
.back{
    padding: 32px 0;
}
.content{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.wrapper ul{
    margin: 0;
    padding-left: 17px;
    line-height: 1.4em;
}
.wrapper li::marker {
    color: #C85D9F;
}
.wrapper a{
    color: #C85D9F;
}
.wrapper a:hover{
    text-decoration: underline;
}
